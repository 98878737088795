// @flow
import React from 'react';

import styles from './styles.module.scss';

const Arrow = () => (
   <svg
      className={`${styles.arrowIcon}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 70 10'
   >
      <path className={styles.line} d='M 0,5 l65,0' />
      <path d='M 0,5 l5,3.8' />
      <path d='M 0,5 l5,-3.8' />
   </svg>
);

export default Arrow;

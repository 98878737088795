// @flow
import * as React from 'react';

import createMarkup from '%/createMarkup';
import styles from './styles.module.scss';

type Props = {|
   children: string,
   className?: string,
|};

const MainHeading = ({ children, className }: Props) => (
   <h1
      dangerouslySetInnerHTML={createMarkup(children)}
      className={`${styles.heading} ${className || ''}`}
   />
);

export default MainHeading;

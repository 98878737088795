// @flow
import React from 'react';
import { Link } from 'gatsby';

import styles from './styles.module.scss';

type Props = {|
   to: string,
   children: string,
   onClick: () => void,
|};

const HeaderNavLink = ({ to, children, onClick }: Props) => (
   <span className={styles.linkWrapper} onClick={onClick}>
      <Link className={styles.link} to={to}>
         {children}
      </Link>
   </span>
);

export default HeaderNavLink;

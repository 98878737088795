// @flow
import React from 'react';
import { Link } from 'gatsby';

import styles from './styles.module.scss';

type Props = {|
   children: string,
   inactive: boolean,
   path: string,
|};

const LocaleLink = ({ children, inactive, path }: Props) => {
   if (inactive) {
      return <span className={styles.inactive}>{children}</span>;
   }
   return (
      <Link to={path} className={styles.link}>
         {children}
      </Link>
   );
};

export default LocaleLink;

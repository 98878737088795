// @flow
import React from 'react';

import styles from './styles.module.scss';

type Props = {|
   active: boolean,
   handler: () => void,
|};

const HeaderHamb = ({ active, handler }: Props) => (
   <button
      onClick={handler}
      className={`${styles.hamb} ${active ? styles.active : ''}`}
   >
      <span className={styles.item} />
      <span className={styles.item} />
      <span className={styles.item} />
   </button>
);

export default HeaderHamb;

// @flow
import React from 'react';

import styles from './styles.module.scss';

type Props = {|
   children: string,
   className?: string,
|};

const Paragraph = ({ children, className }: Props) => (
   <p className={`${styles.paragraph} ${className || ''}`}>{children}</p>
);

export default Paragraph;

// @flow
import React from 'react';

import styles from './styles.module.scss';
import Arrow from '#/Arrow/Arrow';

type Props = {|
   disabled: boolean,
   handler: () => void,
   right?: boolean,
   className?: string,
|};

const SliderArrow = ({ handler, disabled, right, className }: Props) => (
   <button
      onClick={handler}
      className={`${styles.button} ${right ? styles.right : styles.left} ${
         disabled ? styles.disabled : ''
      } ${className || ''}`}
   >
      <span className={styles.arrow}>
         <Arrow />
      </span>
   </button>
);

export default SliderArrow;

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Props = {|
   title: string,
   lang: string,
   intl: any,
   description?: string,
|};

function SEO({ description, lang, title, intl }: Props) {
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  author
               }
            }
         }
      `,
   );

   const metaDescription = description || intl.messages['meta.description'];
   const titleTemplate =
      title === 'Ungelt Group'
         ? `${title}`
         : `${title} | ${site.siteMetadata.title}`;
   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={titleTemplate}
         meta={[
            {
               name: 'description',
               content: metaDescription,
            },
            {
               property: 'og:title',
               content: title,
            },
            {
               property: 'og:description',
               content: metaDescription,
            },
            {
               property: 'og:type',
               content: 'website',
            },
            {
               name: 'twitter:card',
               content: 'summary',
            },
            {
               name: 'twitter:creator',
               content: site.siteMetadata.author,
            },
            {
               name: 'twitter:title',
               content: title,
            },
            {
               name: 'twitter:description',
               content: metaDescription,
            },
         ]}
      >
         <link
            href='https://fonts.googleapis.com/css?family=Montserrat:400,600&amp;subset=latin-ext'
            rel='stylesheet'
         />
      </Helmet>
   );
}

export default injectIntl(SEO);

// @flow
import React, { Component } from 'react';
import { Link } from 'gatsby';

import Logo from './Logo/Logo';
import Navigation from './Navigation';
import Hamb from './Hamb/Hamb';

import styles from './styles.module.scss';

type State = {|
   active: boolean,
|};

type Props = {|
   intlLink: string,
|};

export default class Header extends Component<Props, State> {
   state = {
      active: false,
   };

   componentDidMount() {
      this._isMounted = true;
   }

   componentWillUnmount() {
      this._isMounted = false;
   }

   _isMounted = false;

   _toggleNav = () => {
      this.setState(({ active }) => ({
         active: !active,
      }));
   };

   _closeNav = () => {
      if (!this.state.active || !this._isMounted) return;
      this.setState({
         active: false,
      });
   };

   render() {
      const { active } = this.state;
      return (
         <header className={styles.header}>
            <div
               className={`${styles.content} ${
                  active ? styles.fixed : ''
               } container`}
            >
               <div className={styles.inner}>
                  <Link to='/'>
                     <Logo />
                  </Link>
                  <Navigation
                     active={active}
                     intlLink={this.props.intlLink}
                     closeNav={this._closeNav}
                  />
                  <Hamb active={active} handler={this._toggleNav} />
               </div>
            </div>
         </header>
      );
   }
}

// @flow
import React from 'react';

import styles from './styles.module.scss';

type Props = {|
   children: string,
   className?: string,
|};

const Label = ({ children, className }: Props) => (
   <h3 className={`${styles.label} ${className || ''}`}>{children}</h3>
);

export default Label;

// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import { uniq } from 'ramda';
import { Consumer } from '%/context';

import styles from './styles.module.scss';
import Link from './Link/Link';
import LocaleLink from './LocaleLink';

type Props = {|
   active: boolean,
   intl: any,
   intlLink: string,
   closeNav: () => void,
|};

const HeaderNavigation = ({ active, intl, intlLink, closeNav }: Props) => {
   const { messages } = intl;
   const mapped = Object.keys(messages)
      .filter(item => item.indexOf('header') !== -1)
      .map(item => {
         return item.slice(0, item.lastIndexOf('.'));
      });
   const menu = uniq(mapped).reduce(
      (acc, item) => [
         ...acc,
         {
            label: messages[`${item}.label`],
            path: messages[`${item}.path`],
         },
      ],
      [],
   );

   return (
      <Consumer>
         {({ project, locale }) => (
            <div
               className={`${styles.wrapper} ${
                  active ? styles.navActive : ''
               } ${project ? styles.project : ''}`}
            >
               <nav className={styles.nav}>
                  {menu.map(link => (
                     <Link key={link.label} to={link.path} onClick={closeNav}>
                        {link.label}
                     </Link>
                  ))}
               </nav>
               <div className={styles.lang}>
                  <LocaleLink path={intlLink} inactive={locale === 'cs'}>
                     CZ
                  </LocaleLink>
                  <span className={styles.slash}>/</span>
                  <LocaleLink path={intlLink} inactive={locale === 'en'}>
                     EN
                  </LocaleLink>
               </div>
            </div>
         )}
      </Consumer>
   );
};

export default injectIntl(HeaderNavigation);
